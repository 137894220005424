import { Routes } from "react-router-dom";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";

export default function PrivateRoute({ children, ...rest }) {
  let [auth, setAuth] = useState(true);
  const { currentState } = useSelector(
    (state) => ({ currentState: state.auth }),
    shallowEqual
  );
  const { user } = currentState;
  const location = useLocation();

  useEffect(() => {
    if (user) {
      setAuth(false);
    } else {
      window.location.href = `/signin?redirectTo=${location.pathname}`;
    }
  }, [user, rest.location, location.pathname]);

  if (auth) return null;

  return <Routes {...rest}>{children}</Routes>;
}
