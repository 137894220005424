import { Link } from "react-router-dom";

export default function Breadcrumb({ base, active }) {
  return (
    <>
      <div className="quietSans d-flex text-primary">
        <Link to={"/"+base} className="text-capitalize text-decoration-none text-primary">
          {base}
        </Link>
        <span className="mx-2">&gt;</span>
        <p>{active}</p>
      </div>
    </>
  );
}
