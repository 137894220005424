import { useEffect, useState } from "react";
import { Container, Row, Col, Dropdown, Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { logout } from "../auth/AuthSlice";

export default function Header({ title }) {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="bg-primary py-2">
        <Container fluid>
          <Row className="align-items-center">
            <Col xs="2" className="pt-2">
              <img
                src="/images/white_logo_vertical.png"
                alt="Quarrter Logo"
                width="85px"
                className="d-none d-lg-block ms-4 mt-2"
              />
              <svg
                width="24"
                height="14"
                viewBox="0 0 24 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="d-block d-lg-none"
                data-bs-toggle="offcanvas"
                data-bs-target="#offcanvas"
              >
                <path
                  d="M1.6125 2.19881H22.785C23.1563 2.19881 23.46 1.90632 23.46 1.53507C23.46 1.17507 23.1563 0.882568 22.785 0.882568H1.6125C1.24125 0.882568 0.94875 1.17507 0.94875 1.53507C0.94875 1.90632 1.24125 2.19881 1.6125 2.19881ZM1.6125 7.29507H22.785C23.1563 7.29507 23.46 7.00257 23.46 6.63132C23.46 6.26006 23.1563 5.96757 22.785 5.96757H1.6125C1.24125 5.96757 0.94875 6.26006 0.94875 6.63132C0.94875 7.00257 1.24125 7.29507 1.6125 7.29507ZM1.6125 12.38H22.785C23.1563 12.38 23.46 12.0876 23.46 11.7163C23.46 11.3451 23.1563 11.0525 22.785 11.0525H1.6125C1.24125 11.0525 0.94875 11.3451 0.94875 11.7163C0.94875 12.0876 1.24125 12.38 1.6125 12.38Z"
                  fill="white"
                />
              </svg>
            </Col>
            <Col xs="8" className="pt-2">
              <h6 className="text-center text-white text-uppercase fw-bold mb-0">
                {title}
              </h6>
            </Col>
            <Col xs="2" lg="2" className="pt-2 text-end pe-3">
              <Dropdown>
                <Dropdown.Toggle variant="" id="dropdown-basic">
                  <img
                    src="/images/profile.svg"
                    alt="Profile Toggle"
                    width="25px"
                  />
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item href="/company/edit">
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 31 31"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clipPath="url(#clip0_4350_2538)">
                        <path
                          d="M27.2468 25.233C29.4408 22.5892 30.7621 19.1966 30.7621 15.5005C30.7621 7.08489 23.9155 0.238281 15.5 0.238281C7.0844 0.238281 0.237793 7.08489 0.237793 15.5005C0.237793 19.1966 1.55916 22.5892 3.75318 25.233C3.78879 25.2876 3.83017 25.3398 3.88308 25.3839C3.88376 25.3846 3.88477 25.3849 3.88545 25.3856C6.68691 28.6724 10.8528 30.7626 15.5 30.7626C20.1471 30.7626 24.313 28.6724 27.1145 25.3856C27.1155 25.3849 27.1162 25.3846 27.1172 25.3839C27.1698 25.3395 27.2111 25.2876 27.2468 25.233ZM15.5 1.59492C23.1673 1.59492 29.4055 7.83308 29.4055 15.5005C29.4055 18.6102 28.3792 21.4849 26.6475 23.8038C24.585 21.5975 21.9413 20.0764 19.0496 19.3869C21.2531 18.1425 22.7468 15.782 22.7468 13.0755C22.7468 9.07949 19.4959 5.82865 15.5 5.82865C11.504 5.82865 8.25315 9.07949 8.25315 13.0755C8.25315 15.7816 9.74681 18.1422 11.9503 19.3865C9.05865 20.0761 6.4149 21.5972 4.35248 23.8034C2.62073 21.4849 1.59443 18.6102 1.59443 15.5005C1.59443 7.83308 7.83259 1.59492 15.5 1.59492ZM15.5 18.9656C12.2522 18.9656 9.60979 16.3233 9.60979 13.0755C9.60979 9.82767 12.2522 7.18494 15.5 7.18494C18.7478 7.18494 21.3902 9.82734 21.3902 13.0751C21.3902 16.3229 18.7478 18.9656 15.5 18.9656ZM15.5 29.406C11.4348 29.406 7.77154 27.6522 5.22683 24.8616C7.8621 21.9713 11.5718 20.3223 15.5 20.3223C19.4278 20.3223 23.1378 21.9716 25.7731 24.8616C23.2284 27.6522 19.5651 29.406 15.5 29.406Z"
                          fill="#713C8C"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_4350_2538">
                          <rect width="31" height="31" fill="#713C8C" />
                        </clipPath>
                      </defs>
                    </svg>
                    <span className="ms-2 text-primary dmSans">Profile</span>
                  </Dropdown.Item>
                  <Dropdown.Item onClick={() => setShow(true)}>
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M17.1967 15.182L18.2573 16.2426L22.5 12L18.2573 7.75732L17.1967 8.81801L19.6287 11.25H9V12.75H19.6287L17.1967 15.182Z"
                        fill="#713C8C"
                      />
                      <path
                        d="M18.3511 18.364C16.6514 20.0639 14.3915 21 11.9876 21C9.58355 21 7.32328 20.0639 5.62331 18.364C3.9233 16.6641 3 14.404 3 12C3 9.5962 3.9233 7.33617 5.62336 5.6362C7.32337 3.93623 9.58359 3 11.9876 3C14.3913 3 16.6513 3.93623 18.3511 5.63616C18.469 5.75429 18.5836 5.87562 18.6949 6H20.6041C18.7069 3.27994 15.5553 1.5 11.9876 1.5C6.18848 1.5 1.5 6.20147 1.5 12C1.5 17.7991 6.18848 22.5 11.9876 22.5C15.5554 22.5 18.7072 20.7202 20.6043 18H18.6951C18.5838 18.1245 18.4691 18.2459 18.3511 18.364Z"
                        fill="#713C8C"
                      />
                    </svg>
                    <span className="ms-2 text-primary dmSans">Logout</span>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Col>
          </Row>
        </Container>
      </div>
      {show && (
        <Modal
          onHide={() => setShow(false)}
          centered
          show={true}
          className="modalWrapper"
        >
          <Modal.Body className="bg-white text-center p-5">
            <p className="mb-3 inter fw-600">
              Do you want to logout from Quarrter?
            </p>
            <button
              onClick={() => dispatch(logout())}
              className="btn btn-primary text-white"
            >
              Logout
            </button>
          </Modal.Body>
        </Modal>
      )}
    </>
  );
}
