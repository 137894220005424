import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

export default function Error() {
  return (
    <Container className="py-5 h-100 align-items-center error-bg">
      <Row className="py-5">
        <Col lg="6" className="mt-lg-5 pt-lg-3">
          <h4 className="dmSans text-primary mt-lg-5 pt-lg-5">
            404 - Page Not Found
          </h4>
          <h5 className="dmSans text-primary">Well This is Awkward!</h5>
          <p className="quietSans">
            We'll just carry this away like it was never here and you never saw
            it. Deal? <br className="d-none d-lg-inline" />
            Please return to home.
          </p>
          <Link
            to="/dashboard"
            className="text-white bg-primary btn dmSans text-decoration-none"
          >
            Return to Home
          </Link>
        </Col>
        <Col lg="6">
          <img src="/images/error.svg" alt="Error" width="100%" />
        </Col>
      </Row>
    </Container>
  );
}
