import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import { useEffect } from "react";
import { logout } from "./auth/AuthSlice";
import { Routes, Route, Navigate } from "react-router-dom";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import PrivateRoute from "./PrivateRoute";
import Login from "./pages/auth/Login";
import Register from "./pages/auth/Register";
import Dashboard from "./pages/dashboard";
import Employees from "./pages/employees";
import Reports from "./pages/reports";
import EditCompany from "./pages/company/Edit";
import CreateEmployee from "./pages/employees/Create";
import EditEmployee from "./pages/employees/Edit";
import EmployeeLoans from "./pages/employees/Loans";
import Loans from "./pages/loans";
import LoanDetails from "./pages/loans/LoanDetails";
import Error from "./pages/Error";
import ForgotPassword from "./pages/auth/ForgotPassword";
import ResetPassword from "./pages/auth/ResetPassword";

function App() {
  const { currentState } = useSelector(
    (state) => ({ currentState: state.auth }),
    shallowEqual
  );

  const { user } = currentState;
  const dispatch = useDispatch();

  useEffect(() => {
    window.addEventListener("unload", function () {
      dispatch(logout());
    });
  }, [user, dispatch]);

  return (
    <Routes>
      <Route path="/" element={<Navigate to="/signin" />} />
      <Route path="/signin" element={<Login />} />
      <Route path="/register" element={<Register />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/reset-password" element={<ResetPassword />} />
      <Route
        path="/*"
        element={
          <PrivateRoute>
            <Route path="/dashboard" element={<Dashboard user={user} />} />
            <Route path="/company/edit" element={<EditCompany user={user} />} />
            <Route path="/loans" element={<Loans user={user} />} />
            <Route path="/loans/:type/details/:id" element={<LoanDetails />} />
            <Route path="/employees" element={<Employees user={user} />} />
            <Route path="/employees/create" element={<CreateEmployee />} />
            <Route path="/employees/edit/:id" element={<EditEmployee />} />
            <Route
              path="/employees/:employee/loans/:id"
              element={<EmployeeLoans user={user} />}
            />
            <Route path="/reports" element={<Reports user={user} />} />
            <Route path="*" element={<Error />} />
          </PrivateRoute>
        }
      />
    </Routes>
  );
}

export default App;
