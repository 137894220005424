import { Container, Button, Form, Row, Col } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useState } from "react";
import { Link } from "react-router-dom";

export default function ForgotPassword() {
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [done, setDone] = useState(false);

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Please enter a valid email")
        .required("Please enter your email"),
    }),
    onSubmit: async (values) => {
      setLoading(true);
      try {
        const res = await fetch(
          `${process.env.REACT_APP_BASE_API}/accounts/company/request-reset-email/`,
          {
            method: "POST",
            body: JSON.stringify(values),
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        if (res.ok) {
          setLoading(false);
          setDone(true);
        } else {
          throw new Error(res);
        }
      } catch (error) {
        setLoading(false);
        setError("Something went wrong. Please check the email and try again.");
      }
    },
  });

  return (
    <>
      <div className="auth-bg p-0">
        <Container fluid>
          <Row className="justify-content-between">
            <Col lg="6" className="pt-5">
              <div className="ps-lg-5 d-flex align-items-center justify-content-center justify-content-lg-start">
                <img
                  src="/images/quarrter-logo.png"
                  className="pb-3 me-1"
                  width={120}
                  alt="Quarrter Logo"
                />
                <h4 className="font-nexa fw-bold">Corporate</h4>
              </div>
              <div className="row px-lg-5 px-3 pt-lg-5 my-5 me-lg-4">
                <div className="col-lg-10 mt-lg-4 mx-auto">
                  {!done ? (
                    <>
                      <h4 className="mt-lg-4 mb-4 text-center font-nexa fw-bold">
                        Forgot Your Password?
                      </h4>
                      {error && (
                        <small className="text-danger text-center mb-4 d-block">{error}</small>
                      )}
                      <Form
                        onSubmit={formik.handleSubmit}
                        onChange={() => setError(null)}
                      >
                        <Form.Group className="mb-3">
                          <Form.Label className="font-source fw-semibold">
                            Company E-mail
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="xxx@companydomain.com"
                            className="font-source"
                            {...formik.getFieldProps("email")}
                          />
                          {formik.touched.email && formik.errors.email ? (
                            <p className="text-danger">{formik.errors.email}</p>
                          ) : null}
                        </Form.Group>
                        <Button
                          variant="primary"
                          type="submit"
                          className="w-75 d-block mx-auto py-2"
                        >
                          {loading ? (
                            <>
                              Loading&nbsp;
                              <div
                                className="spinner-border text-light"
                                role="status"
                              ></div>
                            </>
                          ) : (
                            "Reset Password"
                          )}
                        </Button>
                      </Form>
                    </>
                  ) : (
                    <>
                      <p className="text-center mt-lg-5">
                        A Password reset link has been sent to your email.
                        Please click the link and follow the steps to reset your
                        password.
                      </p>
                    </>
                  )}
                  <small className="text-center d-block mt-3">
                    <Link to="/signin" className="text-primary">
                      Back to Login
                    </Link>
                  </small>
                </div>
              </div>
            </Col>
            <Col lg="6" className="d-lg-block d-none p-0 bg-dark">
              <div className="d-flex justify-content-center align-items-center h-100">
                <img
                  src="/images/signin.svg"
                  className="mx-auto d-block"
                  alt="Signin"
                />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}
