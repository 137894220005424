import { Link } from "react-router-dom";

export default function ErrorComponent({ message }) {
  return (
    <>
      <div
        className="main-section font-source justify-content-center text-center d-flex flex-column align-items-center"
        style={{ height: "100%" }}
      >
        <div>
          <img
            src="/images/something-went-wrong.svg"
            alt="Something went wrong"
            width={400}
          />
          <h4 className="dmSans text-primary mt-lg-5">Something went wrong!</h4>
          <p className="quietSans">{message}</p>
          <Link to={-1} className="btn btn-primary text-white px-5 py-2">Go Back</Link>
        </div>
      </div>
    </>
  );
}
