import axios from "axios";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const initialUserState = {
  userDetails: {},
  userToken: null,
  loading: false,
  success: false,
  error: null,
};

export const userDetailsAsync = createAsyncThunk(
  "userDetailsAsync",
  async (param, { rejectWithValue }) => {
    const { user_id, token } = param;
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_API}/accounts/api/quarrter-client-user-detail/${user_id}/`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const userSlice = createSlice({
  name: "user",
  initialState: initialUserState,
  reducers: {
    storeExternalToken: (state, action) => {
      const token = action.payload;
      const { userDetails } = state;
      return {
        userDetails,
        userToken: token,
        loading: false,
        success: false,
        error: null,
      };
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(userDetailsAsync.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.error = null;
        state.loading = false;
        state.userDetails = action.payload;
      })
      .addCase(userDetailsAsync.pending, (state, _) => {
        state.error = null;
        state.loading = true;
        state.status = "pending";
      })
      .addCase(userDetailsAsync.rejected, (state, _) => {
        state.loading = false;
        state.status = "rejected";
        state.error = true;
      });
  },
});

export const { storeExternalToken } = userSlice.actions;
export default userSlice.reducer;
