import {
  Alert,
  Col,
  FormControl,
  FormGroup,
  FormLabel,
  FormSelect,
  InputGroup,
  Modal,
  Row,
} from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import Breadcrumb from "../../../components/Breadcrumb";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useState } from "react";
import { useFetch } from "../../../hooks/useFetch";
import Loader from "../../../components/Loader";
import ErrorComponent from "../../../components/ErrorComponent";
import { shallowEqual, useSelector } from "react-redux";

export default function Form({ edit }) {
  const navigate = useNavigate();
  const { currentState } = useSelector(
    (state) => ({ currentState: state.auth }),
    shallowEqual
  );
  const { user } = currentState;
  const [loader, setLoader] = useState(false);
  const { id } = useParams();
  const [show, setShow] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState({ value: "", error: "" });
  const { data, loading, error, postData, setError, setUrl } = useFetch(
    `${process.env.REACT_APP_BASE_API}/accounts/api/corporate-partners/retirieve/${id}/`,
    edit
  );
  const { data: Company, loading: CompanyLoading } = useFetch(
    `${process.env.REACT_APP_BASE_API}/accounts/api/company/retrieve/${user?.company}/`
  );

  console.log(data)

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      full_name: data?.full_name || "",
      email: data?.email || "",
      address: data?.address || "",
      salary: data?.salary || "",
      staff_id: data?.staff_id || "",
      phone_number: data?.phone_number || "",
      position: data?.position || "",
      company: user?.company,
      guarantor: data?.guarantor || "",
      guarantor_address: data?.guarantor_address || "",
      guarantor_phone_number: data?.guarantor_phone_number || "",
      department: data?.department || "",
    },
    validationSchema: Yup.object({
      full_name: Yup.string().required(
        "Please enter the full name of the employee"
      ),
      email: Yup.string()
        .email("Please enter a valid email address")
        .required("Please enter the employee's corporate email"),
      address: Yup.string().required(
        "Please enter the employee's residential address"
      ),
      salary: Yup.number()
        .transform((o, v) => parseFloat(v.replace(/,/g, "")))
        .typeError("Salary must be a number")
        .required("Current Salary is required."),
      position: Yup.string().required("Current Position is required."),
      guarantor: Yup.string().required("Guarantor is required."),
      guarantor_address: Yup.string().required(
        "Guarantor Address is required."
      ),
      guarantor_phone_number: Yup.string().required(
        "Guarantor Phone Number is required."
      ),
      department: Yup.string(),
    }),
    onSubmit: (values) => {
      values.staff_id = values.staff_id.replace(/ /g, "")
      setLoader({ submit: true });
      if (edit) {
        setUrl(
          `${process.env.REACT_APP_BASE_API}/accounts/api/corporate-partners/retirieve/${id}/`
        );
        postData("PUT", values, () => navigate("/employees"));
      } else {
        setUrl(
          `${process.env.REACT_APP_BASE_API}/accounts/api/corporate-partners/create/`,
          false
        );
        postData("POST", values, () => navigate("/employees"));
      }
    },
  });

  const deleteEmployee = () => {
    setLoader({ modal: true });
    setUrl(
      `${process.env.REACT_APP_BASE_API}/accounts/api/quarrter-client-login/`,
      false
    );
    postData("POST", { email: user?.email, password: password.value }, () => {
      setUrl(
        `${process.env.REACT_APP_BASE_API}/accounts/api/corporate-partners/delete/${id}/`,
        false
      );
      postData("DELETE", null, () => navigate("/employees"));
    });
  };

  return (
    <>
      <Col lg="10" className="main-section font-source">
        {(loading || CompanyLoading) && <Loader />}
        {error?.status === 404 ? (
          <ErrorComponent
            message={
              "The requested employee data was not found. Please check and try again."
            }
          />
        ) : (
          <div className="px-lg-4 px-3">
            {error?.status && error?.status !== 404 && (
              <Row className="mt-5">
                <Col xs="12">
                  <Alert
                    variant={"danger"}
                    className="fade text-center"
                    onClose={() => setError(null)}
                    dismissible
                  >
                    Something went wrong. Please try again.
                  </Alert>
                </Col>
              </Row>
            )}
            <div className="mt-5 mb-3">
              <Breadcrumb
                base={"employees"}
                active={`${
                  edit ? "Edit Employee Details" : "Add New Employee"
                }`}
              />
            </div>
            {edit && (
              <>
                <div className="mt-4 mb-5 d-flex border-bottom">
                  <h6 className="text-primary py-2 mb-0 border-bottom fw-600 quietSans tab-title">
                    Employee Details
                  </h6>
                  <h6 className="py-2 ms-4 mb-0 quietSans">
                    <Link to={`/employees/${data?.quarrter_id}/loans/${id}`}>
                      Loan History
                    </Link>
                  </h6>
                </div>
              </>
            )}
            <h5 className="mb-0">Personal Details</h5>
            <hr className="mt-2" />
            <form
              onSubmit={formik.handleSubmit}
              onChange={() => setError(null)}
            >
              <Row>
                <Col lg="6">
                  <FormGroup className="mb-4">
                    <FormLabel className="fw-semibold">Employee Name</FormLabel>
                    <FormControl
                      type="text"
                      placeholder="Employee's Full Name"
                      {...formik.getFieldProps("full_name")}
                    ></FormControl>
                    {formik.touched.full_name && formik.errors.full_name && (
                      <span className="text-danger">
                        {formik.errors.full_name}
                      </span>
                    )}
                  </FormGroup>
                </Col>
                <Col lg="6">
                  <FormGroup className="mb-4">
                    <FormLabel className="fw-semibold">
                      Corporate Email Address
                    </FormLabel>
                    <FormControl
                      type="text"
                      placeholder="xxx@companydomain.com"
                      {...formik.getFieldProps("email")}
                    ></FormControl>
                    {formik.touched.email && formik.errors.email && (
                      <span className="text-danger">{formik.errors.email}</span>
                    )}
                  </FormGroup>
                </Col>
                <Col lg="6">
                  <FormGroup className="mb-4">
                    <FormLabel className="fw-semibold">Staff ID</FormLabel>
                    <FormControl
                      type="text"
                      placeholder="Staff ID"
                      {...formik.getFieldProps("staff_id")}
                    ></FormControl>
                    {formik.touched.staff_id && formik.errors.staff_id && (
                      <span className="text-danger">
                        {formik.errors.staff_id}
                      </span>
                    )}
                  </FormGroup>
                </Col>
                {Company?.company_departments && (
                  <Col lg="6">
                    <FormGroup className="mb-4">
                      <FormLabel className="fw-semibold">Department</FormLabel>
                      <FormSelect {...formik.getFieldProps("department")}>
                        <option value="">Select Department</option>
                        {Company.company_departments
                          .split(",")
                          .map((department) => (
                            <option
                              value={department}
                            >
                              {department}
                            </option>
                          ))}
                      </FormSelect>
                    </FormGroup>
                  </Col>
                )}
              </Row>
              <h5 className="mt-5 mb-0">Guarantor Details</h5>
              <hr className="mt-2" />
              <Row>
                <Col xs="12">
                  <FormGroup className="mb-4">
                    <FormLabel className="fw-semibold">
                      Guarantor's Name
                    </FormLabel>
                    <FormControl
                      type="text"
                      placeholder="Guarantor's Full Name"
                      {...formik.getFieldProps("guarantor")}
                    ></FormControl>
                    {formik.touched.guarantor && formik.errors.guarantor && (
                      <span className="text-danger">
                        {formik.errors.guarantor}
                      </span>
                    )}
                  </FormGroup>
                </Col>
                <Col xs="6">
                  <FormGroup className="mb-4">
                    <FormLabel className="fw-semibold">
                      Guarantor Phone Number
                    </FormLabel>
                    <FormControl
                      type="text"
                      placeholder="+234-xxx-xxxx-xxxx"
                      {...formik.getFieldProps("guarantor_phone_number")}
                    ></FormControl>
                    {formik.touched.guarantor_phone_number &&
                      formik.errors.guarantor_phone_number && (
                        <span className="text-danger">
                          {formik.errors.guarantor_phone_number}
                        </span>
                      )}
                  </FormGroup>
                </Col>
                <Col xs="6">
                  <FormGroup className="mb-4">
                    <FormLabel className="fw-semibold">
                      Guarantor Address
                    </FormLabel>
                    <FormControl
                      type="text"
                      placeholder="Enter Guarantor Address"
                      {...formik.getFieldProps("guarantor_address")}
                    ></FormControl>
                    {formik.touched.guarantor_address &&
                      formik.errors.guarantor_address && (
                        <span className="text-danger">
                          {formik.errors.guarantor_address}
                        </span>
                      )}
                  </FormGroup>
                </Col>
              </Row>
              <h5 className="mt-5 mb-0">Contact Details</h5>
              <hr className="mt-2" />
              <Row>
                <Col xs="12">
                  <FormGroup className="mb-4">
                    <FormLabel className="fw-semibold">Address</FormLabel>
                    <FormControl
                      type="text"
                      placeholder="Home Address"
                      {...formik.getFieldProps("address")}
                    ></FormControl>
                    {formik.touched.address && formik.errors.address && (
                      <span className="text-danger">
                        {formik.errors.address}
                      </span>
                    )}
                  </FormGroup>
                </Col>
                <Col lg="6">
                  <FormGroup className="mb-4">
                    <FormLabel className="fw-semibold">Phone Number</FormLabel>
                    <FormControl
                      type="text"
                      placeholder="+234-xxx-xxx-xxxx"
                      {...formik.getFieldProps("phone_number")}
                    ></FormControl>
                    {formik.touched.phone_number &&
                      formik.errors.phone_number && (
                        <span className="text-danger">
                          {formik.errors.phone_number}
                        </span>
                      )}
                  </FormGroup>
                </Col>
                <Col lg="6">
                  <FormGroup className="mb-4">
                    <FormLabel className="fw-semibold">
                      Salary&nbsp;
                      <small className="text-muted">
                        (in numeric form, e.g. 100,000)
                      </small>
                    </FormLabel>
                    <FormControl
                      type="text"
                      placeholder="Enter Salary"
                      {...formik.getFieldProps("salary")}
                    ></FormControl>
                    {formik.touched.salary && formik.errors.salary && (
                      <span className="text-danger">
                        {formik.errors.salary}
                      </span>
                    )}
                  </FormGroup>
                </Col>
                <Col lg="12">
                  <FormGroup className="mb-4">
                    <FormLabel className="fw-semibold">Role/Position</FormLabel>
                    <FormControl
                      type="text"
                      placeholder="Position"
                      {...formik.getFieldProps("position")}
                    ></FormControl>
                    {formik.touched.position && formik.errors.position && (
                      <span className="text-danger">
                        {formik.errors.position}
                      </span>
                    )}
                  </FormGroup>
                </Col>
              </Row>
              <div className="d-flex my-80 mb-5">
                {edit && (
                  <button
                    type="button"
                    onClick={() => setShow(true)}
                    className="btn btn-danger inter fw-700"
                  >
                    Delete Employee
                  </button>
                )}
                <Link to="/employees" className="btn inter fw-700 ms-auto me-4">
                  Cancel
                </Link>
                <button
                  type="submit"
                  className="btn btn-primary text-white me-3"
                >
                  {loading.submit ? (
                    <>
                      Loading&nbsp;
                      <div
                        className="spinner-border text-light"
                        role="status"
                      ></div>
                    </>
                  ) : edit ? (
                    "Edit Employee Data"
                  ) : (
                    "Add New Employee"
                  )}
                </button>
              </div>
            </form>
          </div>
        )}
      </Col>
      {show && (
        <Modal
          centered
          show={true}
          onHide={() => setShow(null)}
          className="modalWrapper"
        >
          <Modal.Body className="bg-white text-center p-5">
            <p className="mb-3 inter fw-600">
              {show.delete
                ? "Enter account password to confirm employee account deletion"
                : "Are you sure you want to delete this employee from the database?"}
            </p>
            {show.delete && error?.status === 400 && (
              <p className="text-danger">*Incorrect Password</p>
            )}
            {show.delete && (
              <>
                <FormGroup className="mb-4 text-center">
                  <InputGroup>
                    <FormControl
                      type={showPassword ? "text" : "password"}
                      placeholder="********************"
                      className="text-center"
                      value={password.value}
                      onChange={(e) => {
                        setError(null);
                        setPassword({ value: e.target.value });
                      }}
                    ></FormControl>
                    <InputGroup.Text
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      <i
                        className={`cursor bi bi-eye-${
                          showPassword ? "fill" : "slash"
                        }`}
                      ></i>
                    </InputGroup.Text>
                  </InputGroup>
                  <span className="text-danger">
                    {password.value.length < 1 &&
                      "Please enter your password to continue."}
                  </span>
                </FormGroup>
              </>
            )}
            <button
              disabled={loading && loader?.modal}
              onClick={() => setShow(null)}
              className="btn text-primary me-3 fw-700 inter"
            >
              {show.delete ? "Cancel Action" : "No, cancel action"}
            </button>
            <button
              className="btn btn-danger inter fw-700"
              disabled={loading && loader?.modal}
              onClick={() =>
                show.delete ? deleteEmployee() : setShow({ delete: true })
              }
            >
              {loading && loader?.modal
                ? "Deleting..."
                : show.delete
                ? "Delete Employee"
                : "Yes, delete employee"}
            </button>
          </Modal.Body>
        </Modal>
      )}
    </>
  );
}
