import axios from "axios";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
const initialAuthState = {
  user: null,
  loading: false,
  success: false,
  error: null,
  formData: {},
};

export const userLoginAsync = createAsyncThunk(
  "userLoginAsync",
  async (param, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_API}/accounts/api/quarrter-client-login/`,
        param
      );
      if (response.data.user_type !== 4) {
        return rejectWithValue("*Invalid email and password");
      }
      return response.data;
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const authSlice = createSlice({
  name: "auth",
  initialState: initialAuthState,
  reducers: {
    logout: () => {
      window.location.replace("/signin");
      return initialAuthState;
    },
    storeTokenUser: (state, action) => {
      const userObj = action.payload;
      const { formData } = state;
      return {
        user: userObj,
        loading: false,
        success: false,
        error: null,
        formData,
      };
    },
    storeFormData: (state, action) => {
      const formObj = action.payload;
      const { user } = state;
      return {
        user,
        loading: false,
        success: false,
        error: null,
        formData: { ...formObj },
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(userLoginAsync.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.error = null;
        state.loading = false;
        state.user = action.payload;
      })
      .addCase(userLoginAsync.pending, (state, _) => {
        state.error = null;
        state.loading = true;
        state.status = "pending";
      })
      .addCase(userLoginAsync.rejected, (state, _) => {
        state.loading = false;
        state.status = "rejected";
        state.error = true;
      });
  },
});

export const { logout, storeTokenUser, storeFormData } = authSlice.actions;
export default authSlice.reducer;
