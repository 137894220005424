import { useEffect, useState } from "react";
import { Col } from "react-bootstrap";
import LoadingBar from "react-top-loading-bar";

export default function Loader() {
  const [progress, setProgress] = useState(50);
  const docHeight = document.body.offsetHeight;

  useEffect(() => {
    const interval = setInterval(() => {
      setProgress((prev) => (prev === 100 ? 0 : prev + 1));
    }, 100);

    return () => clearInterval(interval);
  }, []);
  return (
    <>
      <Col
        lg={"10"}
        className="main-section font-source"
        style={{
          height: docHeight - 74,
          minHeight: "100vh",
          position: "absolute",
          backgroundColor: "#ffffff",
          zIndex: "100",
          opacity: 0.9,
        }}
      >
        <LoadingBar color="#6A3A82" progress={progress} height={5} />
      </Col>
    </>
  );
}
