import { ProgressBar, Row } from "react-bootstrap";
import { useFetch } from "../hooks/useFetch";
import { BrowserView } from "react-device-detect";
import { useEffect, useState } from "react";

export default function LoanBucket({ user }) {
  const { data } = useFetch(
    `${process.env.REACT_APP_BASE_API}/accounts/api/company/retrieve/${user.company}/`
  );
  const [percentage, setPercentage] = useState(0);
  const [overdraft, setOverdraft] = useState(null);

  function convertToWords(number) {
    const units = ["", "k", "m", "b", "t"];
    const num = Math.abs(number);
    const sign = number < 0 ? "-" : "";

    if (num < 1000) {
      return sign + num;
    }

    const exp = Math.floor(Math.log10(num) / 3);
    const scaledNum = num / Math.pow(10, exp * 3);
    const roundedNum = scaledNum.toFixed(1);

    return sign + roundedNum + units[exp];
  }

  useEffect(() => {
    setPercentage({
      main: data?.company_loan_amount_taken && data?.company_loan_bucket
        ? ~~((Number(data.company_loan_amount_taken) /
            Number(data.company_loan_bucket)) * 100)
        : 0,
    
      overdraft: data?.company_loan_overdraft_taken && data?.company_loan_overdraft
        ? ~~((Number(data.company_loan_overdraft_taken) /
            Number(data.company_loan_overdraft)) * 100)
        : 0,
    });
    
    setOverdraft(
      data?.company_loan_overdraft > 0
        ? "active"
        : percentage?.main > 50
        ? "eligible"
        : "ineligible"
    );
  }, [data, percentage?.main]);

  return (
    <>
      <div>
        <h4 className="text-primary fw-semibold quietSans mb-4">
          Your Loan Bucket
        </h4>
        <div className="border border-primary loan-bucket rounded-3 p-3">
          <div className="main">
            <h6 className="text-muted quietSans mb-2">Main Bucket</h6>
            <h5 className="text-primary fw-semibold dmSans">
              {data?.company_loan_amount_taken
                ? convertToWords(Math.floor(data?.company_loan_amount_taken))
                : 0}
              <span className="text-muted"> / </span>
              {data?.company_loan_bucket
                ? convertToWords(data?.company_loan_bucket)
                : 0}
            </h5>
            <Row className="mt-4 quietSans">
              <div className="col-3 text-start">
                <BrowserView>
                  <span className="text-muted">
                    <span className="naira">N</span>
                    {percentage?.main < 50
                      ? parseFloat(
                          Math.floor(data?.company_loan_amount_taken)
                        ).toLocaleString()
                      : 0}
                  </span>
                </BrowserView>
              </div>
              <div className="col-6 text-center">
                {percentage?.main >= 50 && (
                  <h6>
                    <span className="naira">N</span>
                    {parseFloat(
                      Math.floor(data?.company_loan_amount_taken)
                    ).toLocaleString()}
                  </h6>
                )}
              </div>
              <div className="col-3 text-end">
                <BrowserView>
                  <span className="text-muted">
                    <span className="naira">N</span>
                    {parseFloat(data?.company_loan_bucket).toLocaleString()}
                  </span>
                </BrowserView>
              </div>
            </Row>
            <ProgressBar variant="primary" now={percentage?.main} />
          </div>
          <div className="overdraft mt-4">
            <h6 className="quietSans mb-2">
              <span className="text-muted">Overdraft</span>
              <button
                className={`btn btn-sm ms-2 px-3 btn-status-${overdraft} text-capitalize`}
              >
                {overdraft}
              </button>
            </h6>
            <h5 className="text-primary fw-semibold dmSans">
              {data?.company_loan_overdraft_taken
                ? convertToWords(Math.floor(data?.company_loan_overdraft_taken))
                : 0}
              <span className="text-muted"> / </span>
              {data?.company_loan_overdraft
                ? convertToWords(data?.company_loan_overdraft)
                : 0}
            </h5>
            <Row className="mt-4 quietSans">
              <div className="col-3 text-start">
                <BrowserView>
                  <span className="text-muted">
                    <span className="naira">N</span>
                    {percentage?.overdraft < 50
                      ? parseFloat(
                          Math.floor(data?.company_loan_overdraft_taken)
                        ).toLocaleString()
                      : 0}
                  </span>
                </BrowserView>
              </div>
              <div className="col-6 text-center">
                {percentage?.overdraft >= 50 && (
                  <h6>
                    <span className="naira">N</span>
                    {parseFloat(
                      Math.floor(data?.company_loan_overdraft_taken)
                    ).toLocaleString()}
                  </h6>
                )}
              </div>
              <div className="col-3 text-end">
                <BrowserView>
                  <span className="text-muted">
                    <span className="naira">N</span>
                    {parseFloat(data?.company_loan_overdraft).toLocaleString()}
                  </span>
                </BrowserView>
              </div>
            </Row>
            <ProgressBar variant="warning" now={percentage?.overdraft} />
          </div>
        </div>
      </div>
    </>
  );
}
