import { Col, Container, Row } from "react-bootstrap";
import Header from "../../components/Header";
import Sidenav from "../../components/Sidenav";
import Loader from "../../components/Loader";
import ErrorComponent from "../../components/ErrorComponent";
import { useFetch } from "../../hooks/useFetch";
import LoanTables from "./LoanTables";
import LoanBucket from "../../components/LoanBucket";

const Dashboard = ({ user }) => {
  const { data, loading, error } = useFetch(
    `${process.env.REACT_APP_BASE_API}/loans/api/user/sum/company/loan-list/${user?.company}/`
  );

  function convertToWords(number) {
    const units = ["", "k", "m", "b", "t"];
    const num = Math.abs(number);
    const sign = number < 0 ? "-" : "";

    if (num < 1000) {
      return sign + num;
    }

    const exp = Math.floor(Math.log10(num) / 3);
    const scaledNum = num / Math.pow(10, exp * 3);
    const roundedNum = scaledNum.toFixed(1);

    return sign + roundedNum + units[exp];
  }

  return (
    <>
      <Header title={"DASHBOARD"} />
      <div className="bg-primary">
        <Container fluid>
          <Row>
            <Sidenav />
            <Col lg="10" className="main-section font-source">
              {loading && <Loader />}
              {error ? (
                <ErrorComponent
                  message={
                    "The data could not be loaded. Please refresh the page or contact support to continue."
                  }
                />
              ) : (
                <div className="px-lg-4 px-3">
                  <Row className="my-5">
                    <Col lg="3" className="p-2">
                      <div className="dashboard-card border border-primary p-3">
                        <div className="d-flex align-items-center">
                          <img
                            src="/images/active-loans.svg"
                            alt="Active Loans"
                            className="me-auto"
                          />
                          <span className="h2 fw-bold dmSans text-primary">
                            {data?.company_active_loans}
                          </span>
                        </div>
                        <h5 className="text-primary fw-semibold mt-5 quietSans">
                          Active Loans
                        </h5>
                        <p className="dmSans">
                          You have {data?.company_active_loans} Active Employee
                          {data?.company_active_loans === 1
                            ? " Loan"
                            : " Loans"}
                        </p>
                      </div>
                    </Col>
                    <Col lg="3" className="p-2">
                      <div className="dashboard-card border border-primary p-3">
                        <div className="d-flex align-items-center">
                          <img
                            src="/images/active-loans.svg"
                            alt="Active Loans"
                            className="me-auto"
                          />
                          <span className="h2 fw-bold dmSans text-primary">
                            {data?.company_loan_requests}
                          </span>
                        </div>
                        <h5 className="text-primary fw-semibold mt-5 quietSans">
                          Loan Request
                        </h5>
                        <p className="dmSans">
                          You have {data?.company_loan_requests} Pending Loan
                          {data?.company_loan_requests === 1
                            ? " Request"
                            : " Requests"}
                        </p>
                      </div>
                    </Col>
                    <Col lg="3" className="p-2">
                      <div className="dashboard-card border border-primary p-3">
                        <div className="d-flex align-items-center">
                          <img
                            src="/images/active-loans.svg"
                            alt="Active Loans"
                            className="me-auto"
                          />
                          <span className="h2 fw-bold dmSans text-primary">
                            {data?.number_of_employees}
                          </span>
                        </div>
                        <h5 className="text-primary fw-semibold mt-5 quietSans">
                          Registered Employees
                        </h5>
                        <p className="dmSans">
                          You have {data?.number_of_employees} Registered
                          {data?.number_of_employees === 1
                            ? " Employee "
                            : " Employees "}
                          on Quarrter
                        </p>
                      </div>
                    </Col>
                    <Col lg="3" className="p-2">
                      <div className="dashboard-card border border-primary p-3">
                        <div className="d-flex align-items-center">
                          <img
                            src="/images/active-loans.svg"
                            alt="Active Loans"
                            className="me-auto"
                          />
                          <span className="h2 fw-bold dmSans text-primary">
                            <span className="naira">N</span>
                            {data?.available_money || data?.available_overdraft_money
                              ? convertToWords(data?.available_money + data?.available_overdraft_money)
                              : 0}
                          </span>
                        </div>
                        <h5 className="text-primary fw-semibold mt-5 quietSans">
                          Available Money
                        </h5>
                        <p className="dmSans">
                          You have <span className="naira">N</span>
                          {data?.available_money || data?.available_overdraft_money
                            ? convertToWords(data?.available_money + data?.available_overdraft_money)
                            : 0}
                          &nbsp;Available for Loan Disbursement
                        </p>
                      </div>
                    </Col>
                  </Row>
                  <LoanBucket user={user} />
                  <LoanTables user={user} />
                </div>
              )}
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Dashboard;
