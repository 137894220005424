import { Container, Row } from "react-bootstrap";
import Header from "../../components/Header";
import Sidenav from "../../components/Sidenav";
import ReportTable from "./ReportTable";
import { useFetch } from "../../hooks/useFetch";
import { useEffect, useState } from "react";

const Reports = ({ user }) => {
  const [reports, setReports] = useState([]);
  const { data, loading, error } = useFetch(
    `${process.env.REACT_APP_BASE_API}/transactions/api/user/corporate/transaction-loan-list/${user?.company}/`
  );

  useEffect(() => {
    const ids = [];
    const loans = [];
    data?.results?.reverse().map((loan) => {
      if (loan.action === "Deposit") {
        loans.push(loan);
      } else {
        if (ids.includes(loan.loan_type_id)) {
          const existing = loans.filter(
            (x) => x.loan_type_id === loan.loan_type_id
          )[0];
          loans.pop(existing);
          loans.push(loan);
          ids.push(loan.loan_type_id);
        } else {
          loans.push(loan);
          ids.push(loan.loan_type_id);
        }
      }
      return null;
    });
    setReports(loans.reverse());
  }, [data]);

  return (
    <>
      <Header title={"REPORTS"} />
      <div className="bg-primary">
        <Container fluid>
          <Row>
            <Sidenav />
            <ReportTable data={reports} loading={loading} error={error} />
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Reports;
