import { Container, Row } from "react-bootstrap";
import Header from "../../components/Header";
import Sidenav from "../../components/Sidenav";
import Overview from "./Overview";

const Loans = ({user}) => {
  return (
    <>
      <Header title={"LOANS"} />
      <div className="bg-primary">
        <Container fluid>
          <Row>
            <Sidenav />
            <Overview user={user} />
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Loans;
