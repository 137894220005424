import { Col, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import Loader from "../../../components/Loader";
import ErrorComponent from "../../../components/ErrorComponent";
import { useFetch } from "../../../hooks/useFetch";

export default function List({ user }) {
  const { data, loading, error } = useFetch(
    `${process.env.REACT_APP_BASE_API}/accounts/api/corporate-partners/list/${user?.company}/`
  );

  return (
    <>
      <Col lg="10" className="main-section">
        {loading && <Loader />}
        {error ? (
          <ErrorComponent
            message={
              "The requested employees list was not found. Please check and try again."
            }
          />
        ) : (
          <div className="text-end my-lg-5 px-lg-4 px-3">
            <Link
              to="/employees/create"
              className="btn btn-primary btn-bg px-4 py-lg-2 my-lg-5 my-3 text-white"
            >
              Add Employee +
            </Link>
            <Table responsive className="employee-list">
              <thead className="thead">
                <tr>
                  <th>#</th>
                  <th>FULL NAME</th>
                  <th>STAFF ID</th>
                  <th>SALARY</th>
                  <th>ROLE/POSITION</th>
                  <th>LOAN STATUS</th>
                  <th>ACTION</th>
                </tr>
              </thead>
              <tbody className="inter text-center employees">
                <>
                  {data?.results?.length > 0 ? (
                    data?.results
                      ?.sort((a, b) => a.id - b.id)
                      ?.map((employee, index) => (
                        <tr key={employee.id} className="employee">
                          <td>{index + 1}</td>
                          <td>{employee.full_name}</td>
                          <td>{employee.staff_id}</td>
                          <td>{employee.salary}</td>
                          <td>{employee.position}</td>
                          <td>
                            <button
                              className={`btn btn-sm btn-primary px-4 btn-status-${
                                employee.status === 1
                                  ? "active"
                                  : employee.status === 3
                                  ? "defaulting"
                                  : "eligible"
                              }`}
                            >
                              {employee.status === 1
                                ? "Active"
                                : employee.status === 3
                                ? "Defaulting"
                                : "Eligible"}
                            </button>
                          </td>
                          <td>
                            <Link
                              to={`/employees/edit/${employee.id}`}
                              className="btn btn-sm btn-outline-secondary"
                            >
                              <img src="/images/btn-edit.svg" alt="" />
                            </Link>
                          </td>
                        </tr>
                      ))
                  ) : (
                    <tr>
                      <td colSpan={7} className="text-center">
                        You have not added any employee
                      </td>
                    </tr>
                  )}
                </>
              </tbody>
            </Table>
          </div>
        )}
      </Col>
    </>
  );
}
