import { Container, Button, Form, Row, Col, InputGroup } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { userLoginAsync } from "../../auth/AuthSlice";

export default function Login() {
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const redirect = searchParams.get("redirectTo");
  const [showPassword, setShowPassword] = useState(false);

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Please enter a valid email")
        .required("Please enter your email"),
      password: Yup.string().required("*Please enter your password"),
    }),
    onSubmit: async (values) => {
      setLoading(true);
      try {
        const response = await dispatch(userLoginAsync(values));
        if (userLoginAsync.fulfilled.match(response)) {
          navigate(redirect || "/dashboard");
        } else {
          setError(
            response.payload.non_field_errors[0].includes("Unable")
              ? "*Invalid password"
              : "*Email is not verified"
          );
        }
      } catch (err) {
        setError("*Invalid email or password");
      } finally {
        setLoading(false);
      }
    },
  });

  return (
    <>
      <div className="auth-bg p-0">
        <Container fluid>
          <Row className="justify-content-between">
            <Col lg="6" className="pt-5">
              <div className="ps-lg-5 d-flex align-items-center justify-content-center justify-content-lg-start">
                <img
                  src="/images/quarrter-logo.png"
                  className="pb-3 me-1"
                  width={120}
                  alt="Quarrter Logo"
                />
                <h4 className="font-nexa fw-bold">Corporate</h4>
              </div>
              <div className="row px-lg-5 px-3 pt-lg-5 my-5 me-lg-4">
                <div className="col-lg-10 mt-lg-4 mx-auto">
                  <h4 className="mt-lg-4 mb-4 text-center font-nexa fw-bold">
                    Sign In to Quarrter Corporate
                  </h4>
                  {error && <p className="text-danger text-center">{error}</p>}
                  <Form
                    onSubmit={formik.handleSubmit}
                    onChange={() => setError(null)}
                  >
                    <Form.Group className="mb-3">
                      <Form.Label className="font-source fw-semibold">
                        Company E-mail
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="xxx@companydomain.com"
                        className="font-source"
                        {...formik.getFieldProps("email")}
                      />
                      {formik.touched.email && formik.errors.email ? (
                        <p className="text-danger">{formik.errors.email}</p>
                      ) : null}
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label className="font-source fw-semibold">
                        Password
                      </Form.Label>
                      <InputGroup className="mb-3">
                        <Form.Control
                          type={showPassword ? "text" : "password"}
                          placeholder="Enter Password"
                          className="font-source"
                          {...formik.getFieldProps("password")}
                        />
                        <InputGroup.Text
                          onClick={() => setShowPassword(!showPassword) }
                        >
                          <i
                            className={`cursor bi bi-eye-${
                              showPassword.password ? "fill" : "slash"
                            }`}
                          ></i>
                        </InputGroup.Text>
                      </InputGroup>
                      <div className="d-flex">
                      {formik.touched.password && formik.errors.password ? (
                        <p className="text-danger">{formik.errors.password}</p>
                      ) : null}
                      <Link to="/forgot-password" className="small text-primary ms-auto">
                        Forgot password?
                      </Link>
                      </div>
                    </Form.Group>
                    <Button
                      variant="primary"
                      type="submit"
                      className="w-75 d-block mx-auto py-2"
                    >
                      {loading ? (
                        <>
                          Loading&nbsp;
                          <div
                            className="spinner-border text-light"
                            role="status"
                          ></div>
                        </>
                      ) : (
                        "Log In"
                      )}
                    </Button>
                    <small className="text-center d-block mt-3">
                      Don't have a corporate account?&nbsp;
                      <Link to="/register" className="text-primary">
                        Get Started
                      </Link>
                    </small>
                  </Form>
                </div>
              </div>
            </Col>
            <Col lg="6" className="d-lg-block d-none p-0 bg-dark">
              <div className="d-flex justify-content-center align-items-center h-100">
                <img
                  src="/images/signin.svg"
                  className="mx-auto d-block"
                  alt="Signin"
                />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}
