import { Col, Row, Table } from "react-bootstrap";
import { useFetch } from "../../hooks/useFetch";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

export default function LoanTables({ user }) {
  const [approved, setApproved] = useState(null);
  const [requests, setRequests] = useState(null);
  const navigate = useNavigate()
  const { data, loading, error } = useFetch(
    `${process.env.REACT_APP_BASE_API}/transactions/api/user/corporate/transaction-loan-list/${user?.company}/`
  );

  useEffect(() => {
    const ids = []
    const loans = [];
    data?.results?.reverse().map((loan) => {
      if(ids.includes(loan.loan_type_id)) {
        const existing = loans.filter(
          (x) => x.loan_type_id === loan.loan_type_id
        )[0];
        loans.pop(existing);
        loans.push(loan);
        ids.push(loan.loan_type_id)
      } else {
        loans.push(loan);
        ids.push(loan.loan_type_id)
      }
      return null
    });
    const approvals = loans.filter((loan) => loan.is_approved === 2);
    const loanRequests = loans.filter((loan) => loan.stage === 1);
    setRequests(loanRequests.reverse());
    setApproved(approvals.reverse());
  }, [data]);

  return (
    <>
      <Row className="my-5">
        <Col lg="6" className="px-4">
          <h5 className="text-primary quietSans fw-semibold">
            Recent Loan Requests
          </h5>
          <Table bordered responsive className="dashboard-table text-center bg-transparent">
            <thead>
              <tr>
                <th>Name</th>
                <th>Staff Id</th>
                <th>Amount</th>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <tr>
                  <td colSpan={3}>Loading data...</td>
                </tr>
              ) : error ? (
                <tr>
                  <td colSpan={3} className="text-danger">
                    Could not fetch data. Reload the page.
                  </td>
                </tr>
              ) : requests?.length === 0 ? (
                <tr>
                  <td colSpan={3}>No Recent Loan Requests</td>
                </tr>
              ) : (
                requests?.slice(0, 5).map((loan, index) => (
                  <tr key={index} className="cursor" onClick={() => navigate(`/loans/${loan.loan_type}/details/${loan.loan_type_id}`)}>
                    <td>
                      {loan.first_name} {loan.last_name}
                    </td>
                    <td>{loan.staff_id}</td>
                    <td>
                      <span className="naira">N</span>
                      {parseFloat(loan.amount).toLocaleString()}
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </Table>
        </Col>
        <Col lg="6" className="px-4">
          <h5 className="text-primary quietSans fw-semibold">
            Recently Approved Loans
          </h5>
          <Table bordered responsive className="dashboard-table text-center bg-transparent">
            <thead>
              <tr>
                <th>Name</th>
                <th>Staff Id</th>
                <th>Amount</th>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <tr>
                  <td colSpan={3}>Loading data...</td>
                </tr>
              ) : error ? (
                <tr>
                  <td colSpan={3} className="text-danger">
                    Could not fetch data. Reload the page.
                  </td>
                </tr>
              ) : approved?.length === 0 ? (
                <tr>
                  <td colSpan={3}>No Recently Approved Loans</td>
                </tr>
              ) : (
                approved?.slice(0, 5).map((loan, index) => (
                  <tr key={index} className="cursor" onClick={() => navigate(`/loans/${loan.loan_type}/details/${loan.loan_type_id}`)}>
                    <td>
                      {loan.first_name} {loan.last_name}
                    </td>
                    <td>{loan.staff_id}</td>
                    <td>
                      <span className="naira">N</span>
                      {parseFloat(loan.amount).toLocaleString()}
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </Table>
        </Col>
      </Row>
    </>
  );
}
