import { Col, Row } from "react-bootstrap";
import Breadcrumb from "../../../components/Breadcrumb";
import { Link, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useFetch } from "../../../hooks/useFetch";
import Loader from "../../../components/Loader";
import ErrorComponent from "../../../components/ErrorComponent";

export default function History({ user }) {
  const { id, employee } = useParams();
  const [history, setHistory] = useState(null);
  const [activeLoan, setActiveLoan] = useState(null);
  const [loanRequest, setLoanRequest] = useState(null);
  const { data, loading, error } = useFetch(
    `${process.env.REACT_APP_BASE_API}/loans/api/user/company/employee-loan-list/${user?.company}/${employee}/`
  );

  useEffect(() => {
    if (data) {
      setHistory(data?.results?.filter((loan) => loan.status === 3));
      const loan = data?.results.sort((a, b) => new Date(b.created_at) - new Date(a.created_at))[0];
      loan?.stage === 1 && loan?.is_approved !== 3 && setLoanRequest(loan);
      (loan?.status === 1 || loan?.status === 2) && setActiveLoan(loan);
    }
  }, [data]);

  return (
    <Col lg="10" className="main-section font-source">
      {loading && <Loader />}
      {error?.status === 404 ? (
        <ErrorComponent
          message={
            "The requested employee data was not found. Please check and try again."
          }
        />
      ) : (
        <div className="px-lg-4 px-3">
          <div className="mt-5 mb-3">
            <Breadcrumb base={"employees"} active={"Employee Loan History"} />
          </div>
          <div className="mt-4 mb-5 d-flex border-bottom">
            <h6 className="py-2 me-4 mb-0 quietSans">
              <Link to={`/employees/edit/${id}`}>Employee Details</Link>
            </h6>
            <h6 className="text-primary py-2 mb-0 border-bottom fw-600 quietSans tab-title">
              Loan History
            </h6>
          </div>
          <h5 className="mb-0">
            {activeLoan ? "Current Loan" : "Loan Request"}
          </h5>
          <hr className="mt-2" />
          {loanRequest && (
            <>
              <Row className="mb-5">
                <Col lg={3}>
                  <Link
                    to={`/loans/${loanRequest?.loan_type}/details/${loanRequest?.id}`}
                  >
                    <div className="card_container rounded p-3">
                      <p className="text-muted mb-0">Loan Amount</p>
                      <h5 className="quietSans fw-600 mt-1 mb-3">
                        <span className="naira">N</span>
                        {parseFloat(loanRequest?.loan_amount).toLocaleString()}
                      </h5>
                      <p className="text-end mb-0">
                        <span className="text-muted">Interest Rate</span>
                        <span className="d-block quietSans fw-600">
                          {loanRequest?.interest_rate}%
                        </span>
                      </p>

                      <p className="text-muted mt-0 mb-0">Monthly Income</p>
                      <h6 className="quietSans fw-600 mt-0">
                        <span className="naira">N</span>
                        {parseFloat(
                          loanRequest?.gross_monthly_income
                        ).toLocaleString()}
                      </h6>
                    </div>
                  </Link>
                </Col>
              </Row>
            </>
          )}
          {activeLoan && (
            <>
              <Row className="mb-5">
                <Col lg={3}>
                  <Link
                    to={`/loans/${activeLoan?.loan_type}/details/${activeLoan?.id}`}
                  >
                    <div className="card_container rounded p-3">
                      <p className="text-muted mb-0">Total Amount To Repay</p>
                      <h5 className="quietSans fw-600 mt-1 mb-3">
                        <span className="naira">N</span>
                        {parseFloat(
                          activeLoan?.amount_to_be_repaid
                        ).toLocaleString()}
                      </h5>
                      <p className="text-end mb-3">
                        <span className="text-muted">Amount To Deduct</span>
                        <span className="d-block quietSans fw-600">
                          <span className="naira">N</span>
                          {parseFloat(
                            activeLoan?.amount_to_be_paid
                          ).toLocaleString()}
                        </span>
                      </p>
                      <p className="text-muted mt-0 mb-0">
                        Next Deduction Date
                      </p>
                      <h6 className="quietSans fw-600 mt-0">
                        {activeLoan?.next_payment_date.split("T")[0]}
                      </h6>
                    </div>
                  </Link>
                </Col>
              </Row>
            </>
          )}
          {!loanRequest && !activeLoan && (
            <>
              <>
                <div className="my-5 py-5 text-center">
                  <p className="fw-600 text-muted">
                    No Current Active Loan or Loan Request
                  </p>
                </div>
              </>
            </>
          )}
          {history?.length > 0 && (
            <>
              <div className="loan-history">
                <h5 className="mb-0">Loan History</h5>
                <hr className="mt-2" />
                <Row className="mb-5 pb-5">
                  {history?.map((loan) => (
                    <Col lg={3} key={loan.id}>
                      <Link to={`/loans/${loan.loan_type}/details/${loan.id}`}>
                        <div className="card_container rounded p-3">
                          <p className="text-muted mb-0">Loan Amount</p>
                          <h5 className="quietSans fw-600 mt-1 mb-3">
                            <span className="naira">N</span>
                            {parseFloat(loan.loan_amount).toLocaleString()}
                          </h5>
                          <p className="text-end mb-0">
                            <span className="text-muted">Interest Rate</span>
                            <span className="d-block quietSans fw-600">
                              {loan.interest_rate}%
                            </span>
                          </p>
                          <p className="text-muted mt-0 mb-0">Date Completed</p>
                          <h6 className="quietSans fw-600 mt-0">
                            {loan.date_completed.split("T")[0]}
                          </h6>
                        </div>
                      </Link>
                    </Col>
                  ))}
                  <Col lg="3" className="d-flex align-items-center">
                    <Link to={"/reports"} className="btn btn-lg text-white btn-primary rounded">
                      View More &gt;&gt;
                    </Link>
                  </Col>
                </Row>
              </div>
            </>
          )}
        </div>
      )}
    </Col>
  );
}
