import { Container, Button, Form, Row, Col, InputGroup } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useState } from "react";
import { Link } from "react-router-dom";

export default function Register() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [done, setDone] = useState(false);
  const [showPassword, setShowPassword] = useState({
    password: false,
    password1: false,
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      company_name: "",
      company_code: "xxxxxx",
      company_username: "",
      email: "",
      company_address: "",
      company_phonenumber: "",
      password1: "",
      password2: "",
    },
    validationSchema: Yup.object({
      company_name: Yup.string().required("*Enter the name of your company"),
      company_username: Yup.string().required(
        "*What should we refer to you as?"
      ),
      company_code: Yup.string(),
      email: Yup.string()
        .email("*Enter a valid email address")
        .required("*Enter your company's official email"),
      company_address: Yup.string().required(
        "*Your business address is required."
      ),
      company_phonenumber: Yup.string().required(
        "*Enter your business phone number"
      ),
      password1: Yup.string()
        .required("*Enter your password")
        .matches(
          /^(?=.*?[A-Z])(?=(.*[a-z]){1,})(?=(.*[\d]){1,})(?=(.*[\W]){1,})(?!.*\s).{8,}$/,
          "*Invalid password. passwords must be least 8 characters long with one uppercase, one lowercase and one special character"
        ),
      password2: Yup.string()
        .required("*Re-enter your company password")
        .oneOf([Yup.ref("password1"), null], "*passwords do not match"),
    }),
    onSubmit: (values) => {
      setLoading(true);
      fetch(
        `${process.env.REACT_APP_BASE_API}/accounts/api/quarrter-company-create/`,
        {
          method: "POST",
          body: JSON.stringify(values),
          headers: {
            "Content-Type": "application/json",
          },
        }
      ).then(async (response) => {
        const res = await response.json();
        setLoading(false);
        window.scrollTo({ top: 0, behavior: "smooth" });
        if (res.token) {
          setDone(true);
        } else {
          setError({
            title: "There were some errors in your submission",
            ...res,
          });
        }
      });
    },
  });

  return (
    <>
      <div className="auth-bg p-0">
        <Container fluid>
          <Row className="justify-content-between">
            <Col lg="6" className="pt-5">
              <div className="ps-lg-5 d-flex align-items-center justify-content-center justify-content-lg-start">
                <img
                  src="/images/quarrter-logo.png"
                  className="pb-3 me-1"
                  width={120}
                  alt="Quarrter Logo"
                />
                <h4 className="font-nexa fw-bold">Corporate</h4>
              </div>
              <div className="row px-lg-5 px-3 my-5 me-lg-4">
                <div className="col-lg-10 mt-lg-4 mx-auto">
                  {!done ? (
                    <>
                      <h4 className="mt-lg-4 mb-4 text-center font-nexa fw-bold">
                        Register Company Details
                      </h4>
                      {error && (
                        <p className="text-danger text-center">
                          {error?.title}
                        </p>
                      )}
                      <Form
                        autoComplete="off"
                        onSubmit={formik.handleSubmit}
                        onChange={() => setError(null)}
                      >
                        <Form.Group className="mb-3">
                          <Form.Label className="font-source fw-semibold">
                            Business Name
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="e.g Quarrter Financial Services Limited"
                            className="font-source"
                            {...formik.getFieldProps("company_name")}
                          />
                          {formik.touched.company_name &&
                          formik.errors.company_name ? (
                            <small className="text-danger">
                              {formik.errors.company_name}
                            </small>
                          ) : null}
                        </Form.Group>
                        <Form.Group className="mb-3">
                          <Form.Label className="font-source fw-semibold">
                            What we should refer to you as
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="e.g Quarrter"
                            className="font-source"
                            {...formik.getFieldProps("company_username")}
                          />
                          {formik.touched.company_username &&
                          formik.errors.company_username ? (
                            <small className="text-danger">
                              {formik.errors.company_username}
                            </small>
                          ) : null}
                        </Form.Group>
                        <Form.Group className="mb-3">
                          <Form.Label className="font-source fw-semibold">
                            Company Email Address
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="xxx@companydomain.com"
                            className="font-source"
                            {...formik.getFieldProps("email")}
                          />
                          <small className="text-danger">
                            {formik.touched.email && formik.errors.email
                              ? formik.errors.email
                              : error?.email?.[0]}
                          </small>
                        </Form.Group>
                        <Form.Group className="mb-3">
                          <Form.Label className="font-source fw-semibold">
                            Business Address
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Business Address"
                            className="font-source"
                            {...formik.getFieldProps("company_address")}
                          />
                          {formik.touched.company_address &&
                          formik.errors.company_address ? (
                            <small className="text-danger">
                              {formik.errors.company_address}
                            </small>
                          ) : null}
                        </Form.Group>
                        <Form.Group className="mb-3">
                          <Form.Label className="font-source fw-semibold">
                            Business Phone Number
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="+234-XXX-XXX-XXXX"
                            className="font-source"
                            {...formik.getFieldProps("company_phonenumber")}
                          />
                          {formik.touched.company_phonenumber &&
                          formik.errors.company_phonenumber ? (
                            <small className="text-danger">
                              {formik.errors.company_phonenumber}
                            </small>
                          ) : null}
                        </Form.Group>
                        <Form.Group className="mb-3">
                          <Form.Label className="font-source fw-semibold">
                            New password&nbsp;
                            <small className="fw-normal">
                              (at least 8 characters long, one special character
                              and one capital letter)
                            </small>
                          </Form.Label>
                          <InputGroup className="mb-3">
                            <Form.Control
                              type={showPassword.password ? "text" : "password"}
                              placeholder="Enter password"
                              className="font-source"
                              {...formik.getFieldProps("password1")}
                            />
                            <InputGroup.Text
                              onClick={() =>
                                setShowPassword({
                                  password: !showPassword.password,
                                })
                              }
                            >
                              <i
                                className={`cursor bi bi-eye-${
                                  showPassword.password ? "fill" : "slash"
                                }`}
                              ></i>
                            </InputGroup.Text>
                          </InputGroup>
                          {formik.touched.password1 &&
                          formik.errors.password1 ? (
                            <small className="text-danger">
                              {formik.errors.password1}
                            </small>
                          ) : null}
                        </Form.Group>
                        <Form.Group className="mb-5">
                          <Form.Label className="font-source fw-semibold">
                            Confirm password
                          </Form.Label>
                          <InputGroup className="mb-3">
                            <Form.Control
                              type={
                                showPassword.password1 ? "text" : "password"
                              }
                              placeholder="Confirm password"
                              className="font-source"
                              {...formik.getFieldProps("password2")}
                            />
                            <InputGroup.Text
                              onClick={() =>
                                setShowPassword({
                                  password1: !showPassword.password1,
                                })
                              }
                            >
                              <i
                                className={`cursor bi bi-eye-${
                                  showPassword.password1 ? "fill" : "slash"
                                }`}
                              ></i>
                            </InputGroup.Text>
                          </InputGroup>
                          {formik.touched.password2 &&
                          formik.errors.password2 ? (
                            <small className="text-danger">
                              {formik.errors.password2}
                            </small>
                          ) : null}
                        </Form.Group>
                        <Button
                          variant="primary"
                          type="submit"
                          className="w-75 d-block mx-auto py-2"
                        >
                          {loading ? (
                            <>
                              Loading&nbsp;
                              <div
                                className="spinner-border text-light"
                                role="status"
                              ></div>
                            </>
                          ) : (
                            "Complete Sign Up"
                          )}
                        </Button>
                        <small className="text-center d-block mt-3">
                          Already have a corporate account?&nbsp;
                          <Link to="/" className="text-primary">
                            Login
                          </Link>
                        </small>
                      </Form>
                    </>
                  ) : (
                    <>
                      <div className="mt-5 pt-3 text-center">
                        <p className="mt-lg-5 pt-lg-5 mb-4 font-nexa fw-bold">
                          Your corporate account was successfully created. An
                          email will be sent to you shortly with a link to
                          verify your account.
                        </p>
                        <Link to="/" className="btn btn-primary text-white">
                          Back to Login
                        </Link>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </Col>
            <Col lg="6" className="d-none d-lg-block p-0">
              <img src="/images/signup.svg" alt="Signup" width="100%" />
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}
