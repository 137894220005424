import { Container, Row } from "react-bootstrap";
import Header from "../../components/Header";
import Sidenav from "../../components/Sidenav";
import Form from "./components/Form";

export default function EditEmployee() {
  return (
    <>
      <Header title={"EMPLOYEES"} />
      <div className="bg-primary">
        <Container fluid>
          <Row>
            <Sidenav />
            <Form edit={true} />
          </Row>
        </Container>
      </div>
    </>
  );
}
