import { useState, useEffect } from "react";
import { shallowEqual, useSelector } from "react-redux";

export const useFetch = (link, start) => {
  const [url, setUrl] = useState(link);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [options, setOptions] = useState(null);
  const { currentState } = useSelector(
    (state) => ({ currentState: state.auth }),
    shallowEqual
  );

  const { user } = currentState;

  const postData = (method, body, onComplete) => {
    setOptions({
      method,
      body: JSON.stringify(body),
      onComplete,
    });
  };

  useEffect(() => {
    const fetchData = async (fetchOptions) => {
      setLoading(true);

      try {
        const res = await fetch(url, {
          ...fetchOptions,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${user?.key}`,
          },
        });
        setLoading(false);
        if (!res.ok) {
          return setError(res);
        } else if (fetchOptions?.onComplete) {
          fetchOptions.onComplete();
        } else {
          const data = await res.json();
          return setData(data);
        }
      } catch (err) {
        setLoading(false);
        return setError(err);
      }
    };

    (start === undefined || start === true || options?.method) &&
      fetchData(options);

    return () => {};
  }, [url, options, user, start]);

  return { data, loading, error, postData, setError, setUrl };
};
