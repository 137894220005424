import { Col, Row, Table } from "react-bootstrap";
import LoanBucket from "../../components/LoanBucket";
import { useFetch } from "../../hooks/useFetch";
import Loader from "../../components/Loader";
import ErrorComponent from "../../components/ErrorComponent";
import { useEffect, useState } from "react";

export default function Overview({ user }) {
  const [loanRequests, setLoanRequests] = useState([]);
  const [repayments, setRepayments] = useState([]);
  const { data, loading, error } = useFetch(
    `${process.env.REACT_APP_BASE_API}/loans/api/user/sum/company/loan-list/${user?.company}/`
  );
  const { data: loans } = useFetch(
    `${process.env.REACT_APP_BASE_API}/transactions/api/user/corporate/transaction-loan-list/${user?.company}/`
  );

  useEffect(() => {
    const ids = [];
    const data = [];
    loans?.results?.reverse().map((loan) => {
      if (ids.includes(loan.loan_type_id)) {
        const existing = data.filter(
          (x) => x.loan_type_id === loan.loan_type_id
        )[0];
        data.pop(existing);
        data.push(loan);
        ids.push(loan.loan_type_id);
      } else {
        data.push(loan);
        ids.push(loan.loan_type_id);
      }
      return null;
    });

    const getWeekNumber = (date) => {
      return Math.ceil(
        Math.floor(
          (new Date(date) - new Date(new Date(date).getFullYear(), 0, 1)) /
            (24 * 60 * 60 * 1000)
        ) / 7
      );
    };
    const upcoming = data.filter(
      (loan) =>
        (loan.status === 1 || loan.status === 2) &&
        getWeekNumber(loan.next_payment_date) === getWeekNumber(new Date())
    );
    const requests = data.filter((loan) => loan.stage === 1);
    setLoanRequests(requests.reverse());
    setRepayments(upcoming.reverse());
  }, [loans]);

  return (
    <>
      <Col lg="10" className="main-section font-source">
        {loading && <Loader />}
        {error ? (
          <ErrorComponent
            message={
              "Could not fetch the data. Please try again after some time."
            }
          />
        ) : (
          <div className="px-lg-4 px-3 mt-5">
            <LoanBucket user={user} />
            <Row className="my-5">
              <Col lg={6}>
                <h4 className="text-primary quietSans fw-600 mb-4">Overview</h4>
                <Row>
                  <Col xs={12} className="mb-3">
                    <div className="dashboard-card p-3">
                      <div className="d-flex align-items-center">
                        <img
                          src="/images/active-loans.svg"
                          alt="Active Loans"
                          className="me-auto"
                        />
                        <span className="h2 fw-bold dmSans text-primary">
                          {data?.company_active_loans}
                        </span>
                      </div>
                      <h5 className="text-primary fw-semibold mt-5 quietSans">
                        Active Loans
                      </h5>
                      <p className="dmSans">
                        You currently have {data?.company_active_loans} Active
                        Employee
                        {data?.company_active_loans === 1 ? " Loan" : " Loans"}
                        <br /> on Quarrter
                      </p>
                    </div>
                  </Col>
                  <Col lg={6} className="mb-3">
                    <div className="dashboard-card p-3">
                      <div className="d-flex align-items-center">
                        <img
                          src="/images/active-loans.svg"
                          alt="Active Loans"
                          className="me-auto"
                        />
                        <span className="h2 fw-bold dmSans text-primary">
                          {loanRequests?.length}
                        </span>
                      </div>
                      <h5 className="text-primary fw-semibold mt-5 quietSans">
                        Loan Requests
                      </h5>
                      <p className="dmSans">
                        You have {loanRequests?.length} Loan
                        {loanRequests?.length === 1
                          ? " Request "
                          : " Requests "}
                        from employees
                      </p>
                    </div>
                  </Col>
                  <Col lg={6} className="mb-3">
                    <div className="dashboard-card p-3">
                      <div className="d-flex align-items-center">
                        <img
                          src="/images/active-loans.svg"
                          alt="Active Loans"
                          className="me-auto"
                        />
                        <span className="h2 fw-bold dmSans text-primary">
                          {repayments?.length}
                        </span>
                      </div>
                      <h5 className="text-primary fw-semibold mt-5 quietSans">
                        Upcoming Repayments
                      </h5>
                      <p className="dmSans">
                        You currently have {repayments?.length}
                        {repayments?.length === 1
                          ? " Repayment "
                          : " Repayments "}
                        this week
                      </p>
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col lg={6} className="my-4 my-lg-0">
                <div className="border-lg-start px-4 h-100">
                  <h4 className="text-center text-lg-start text-primary quietSans fw-600 mb-4">
                    Upcoming Repayments
                  </h4>
                  <Table responsive className="bg-transparent">
                    <thead className="thead">
                      <tr className="text-muted text-center">
                        <th>FULL NAME</th>
                        <th>AMOUNT</th>
                        <th>DATE</th>
                        <th>TOTAL TAKEN</th>
                      </tr>
                    </thead>
                    <tbody>
                      {repayments?.length > 0 ? (
                        <>
                          {repayments?.map((loan) => (
                            <tr key={loan.id} className="text-center">
                              <td>
                                {loan.first_name} {loan.last_name}
                              </td>
                              <td>
                                <span className="naira">N</span>
                                {parseFloat(
                                  loan.amount_due
                                ).toLocaleString()}
                              </td>
                              <td>{loan.next_payment_date}</td>
                              <td>
                                <span className="naira">N</span>
                                {parseFloat(loan.amount).toLocaleString()}
                              </td>
                            </tr>
                          ))}
                        </>
                      ) : (
                        <>
                          <tr>
                            <td colSpan={4} className="text-center py-3">
                              No upcoming repayments this week.
                            </td>
                          </tr>
                        </>
                      )}
                    </tbody>
                  </Table>
                </div>
              </Col>
            </Row>
          </div>
        )}
      </Col>
    </>
  );
}
