import { Container, Button, Form, Row, Col, InputGroup } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useState } from "react";
import { Link, useSearchParams } from "react-router-dom";

export default function ResetPassword() {
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [done, setDone] = useState(false);
  const [searchParams] = useSearchParams();
  const uidb64 = searchParams.get("uidb64");
  const token = searchParams.get("token");
  const [showPassword, setShowPassword] = useState({
    password: false,
    password1: false,
  });

  const formik = useFormik({
    initialValues: {
      uidb64,
      token,
      password: "",
      password1: "",
    },
    validationSchema: Yup.object({
      uidb64: Yup.string(),
      token: Yup.string(),
      password: Yup.string()
        .required("Please enter your password")
        .matches(
          /^(?=.*?[A-Z])(?=(.*[a-z]){1,})(?=(.*[\d]){1,})(?=(.*[\W]){1,})(?!.*\s).{8,}$/,
          "Invalid password. Passwords must be least 8 characters long with one uppercase, one lowercase and one special character"
        ),
      password1: Yup.string()
        .required("Please re-enter your password")
        .oneOf([Yup.ref("password"), null], "Passwords do not match"),
    }),
    onSubmit: async (values) => {
      setLoading(true);
      try {
        const res = await fetch(
          `${process.env.REACT_APP_BASE_API}/accounts/password-reset-complete/`,
          {
            method: "PATCH",
            body: JSON.stringify(values),
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        if (res.ok) {
          setLoading(false);
          setDone(true);
        } else {
          throw new Error(res);
        }
      } catch (error) {
        setLoading(false);
        setError(
          "Something went wrong. Please refresh the page and try again."
        );
      }
    },
  });

  return (
    <>
      <div className="auth-bg p-0">
        <Container fluid>
          <Row className="justify-content-between">
            <Col lg="6" className="pt-5">
              <div className="ps-lg-5 d-flex align-items-center justify-content-center justify-content-lg-start">
                <img
                  src="/images/quarrter-logo.png"
                  className="pb-3 me-1"
                  width={120}
                  alt="Quarrter Logo"
                />
                <h4 className="font-nexa fw-bold">Corporate</h4>
              </div>
              <div className="row px-lg-5 px-3 pt-lg-5 my-5 me-lg-4">
                <div className="col-lg-10 mt-lg-4 mx-auto">
                  {!done ? (
                    <>
                      <h4 className="mt-lg-4 mb-4 text-center font-nexa fw-bold">
                        Reset Your Password
                      </h4>
                      {error && (
                        <small className="text-danger text-center mb-4 d-block">
                          {error}
                        </small>
                      )}
                      <Form
                        onSubmit={formik.handleSubmit}
                        onChange={() => setError(null)}
                      >
                        <Form.Group className="mb-3">
                          <Form.Label className="font-source fw-semibold">
                            New password&nbsp;
                            <small className="fw-normal">
                              (at least 8 characters long, one special character
                              and one capital letter)
                            </small>
                          </Form.Label>
                          <InputGroup className="mb-3">
                            <Form.Control
                              type={showPassword.password ? "text" : "password"}
                              placeholder="Enter password"
                              className="font-source"
                              {...formik.getFieldProps("password")}
                            />
                            <InputGroup.Text
                              onClick={() =>
                                setShowPassword({
                                  password: !showPassword.password,
                                })
                              }
                            >
                              <i
                                className={`cursor bi bi-eye-${
                                  showPassword.password ? "fill" : "slash"
                                }`}
                              ></i>
                            </InputGroup.Text>
                          </InputGroup>
                          {formik.touched.password &&
                          formik.errors.password ? (
                            <small className="text-danger">
                              {formik.errors.password}
                            </small>
                          ) : null}
                        </Form.Group>
                        <Form.Group className="mb-5">
                          <Form.Label className="font-source fw-semibold">
                            Confirm New password
                          </Form.Label>
                          <InputGroup className="mb-3">
                            <Form.Control
                              type={
                                showPassword.password1 ? "text" : "password"
                              }
                              placeholder="Confirm password"
                              className="font-source"
                              {...formik.getFieldProps("password1")}
                            />
                            <InputGroup.Text
                              onClick={() =>
                                setShowPassword({
                                  password1: !showPassword.password1,
                                })
                              }
                            >
                              <i
                                className={`cursor bi bi-eye-${
                                  showPassword.password1 ? "fill" : "slash"
                                }`}
                              ></i>
                            </InputGroup.Text>
                          </InputGroup>
                          {formik.touched.password1 &&
                          formik.errors.password1 ? (
                            <small className="text-danger">
                              {formik.errors.password1}
                            </small>
                          ) : null}
                        </Form.Group>
                        <Button
                          variant="primary"
                          type="submit"
                          className="w-75 d-block mx-auto py-2"
                        >
                          {loading ? (
                            <>
                              Loading&nbsp;
                              <div
                                className="spinner-border text-light"
                                role="status"
                              ></div>
                            </>
                          ) : (
                            "Reset Password"
                          )}
                        </Button>
                      </Form>
                    </>
                  ) : (
                    <>
                      <p className="text-center mt-lg-5">
                        Your password was reset successfully. You can now
                        proceed to login with your new password.
                      </p>
                    </>
                  )}
                  <small className="text-center d-block mt-3">
                    <Link to="/signin" className="text-primary">
                      Back to Login
                    </Link>
                  </small>
                </div>
              </div>
            </Col>
            <Col lg="6" className="d-lg-block d-none p-0 bg-dark">
              <div className="d-flex justify-content-center align-items-center h-100">
                <img
                  src="/images/signin.svg"
                  className="mx-auto d-block"
                  alt="Signin"
                />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}
