import {
  Alert,
  Col,
  FormControl,
  FormGroup,
  FormLabel,
  Row,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import Breadcrumb from "../../components/Breadcrumb";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useFetch } from "../../hooks/useFetch";
import Loader from "../../components/Loader";
import ErrorComponent from "../../components/ErrorComponent";
import { useState } from "react";

export default function Form({ user }) {
  const [updated, setUpdated] = useState(false);
  const { data, loading, error, postData, setError } = useFetch(
    `${process.env.REACT_APP_BASE_API}/accounts/api/company/retrieve/${user?.company}/`
  );

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      company_name: data?.company_name || "",
      company_username: data?.company_username || "",
      email: data?.email || "",
      company_address: data?.company_address || "",
      company_phone_number: data?.company_phone_number || "",
      company_departments: data?.company_departments || "",
    },
    validationSchema: Yup.object({
      company_name: Yup.string().required("Your Business Name is required"),
      company_username: Yup.string().required(
        "Your Business Username is required"
      ),
      email: Yup.string().required("Your Business Email is required"),
      company_address: Yup.string().required(
        "Your Business Address is required"
      ),
      company_phone_number: Yup.string().required(
        "Your Business Phone Number is required"
      ),
      company_departments: Yup.string(),
    }),
    onSubmit: (values) => {
      postData("PUT", values, () => {
        setUpdated(true);
        window.scrollTo({ top: 0, behavior: "smooth" });
      });
    },
  });

  return (
    <>
      <Col lg="10" className="main-section font-source">
        {loading && <Loader />}
        {error?.status === 404 ? (
          <ErrorComponent
            message={
              "The requested company details were not found. Please check and try again."
            }
          />
        ) : (
          <div className="px-lg-4 px-3">
            {error?.status && error?.status !== 404 && (
              <Row className="mt-5">
                <Col xs="12">
                  <Alert
                    variant={"danger"}
                    className="fade text-center"
                    onClose={() => setError(null)}
                    dismissible
                  >
                    Something went wrong. Please try again.
                  </Alert>
                </Col>
              </Row>
            )}
            {updated && (
              <Row className="mt-5">
                <Col xs="12">
                  <Alert
                    variant={"success"}
                    className="fade text-center"
                    onClose={() => setUpdated(false)}
                    dismissible
                  >
                    Company Details Updated.
                  </Alert>
                </Col>
              </Row>
            )}
            <div className="mt-5 mb-3">
              <Breadcrumb base={"company"} active={"Edit Company Details"} />
            </div>
            <h4>Company Details</h4>
            <hr />
            <form onSubmit={formik.handleSubmit}>
              <Row>
                <Col lg="6">
                  <FormGroup className="mb-4">
                    <FormLabel className="fw-semibold">Business Name</FormLabel>
                    <FormControl
                      type="text"
                      readOnly
                      placeholder="e.g Quarrter Financial Services Limited"
                      {...formik.getFieldProps("company_name")}
                    ></FormControl>
                  </FormGroup>
                </Col>
                <Col lg="6">
                  <FormGroup className="mb-4">
                    <FormLabel className="fw-semibold">
                      Business Username
                    </FormLabel>
                    <FormControl
                      type="text"
                      placeholder="e.g Quarrter"
                      {...formik.getFieldProps("company_username")}
                    ></FormControl>
                  </FormGroup>
                </Col>
                <Col lg="6">
                  <FormGroup className="mb-4">
                    <FormLabel className="fw-semibold">
                      Company Email Address
                    </FormLabel>
                    <FormControl
                      type="text"
                      readOnly
                      placeholder="xxx@companydomain.com"
                      {...formik.getFieldProps("email")}
                    ></FormControl>
                  </FormGroup>
                </Col>
                <Col lg="6">
                  <FormGroup className="mb-4">
                    <FormLabel className="fw-semibold">Departments</FormLabel>
                    <FormControl
                      type="text"
                      {...formik.getFieldProps("company_departments")}
                    ></FormControl>
                  </FormGroup>
                </Col>
              </Row>
              <h4 className="mt-5">Contact Details</h4>
              <hr />
              <Row>
                <Col lg="6">
                  <FormGroup className="mb-4">
                    <FormLabel className="fw-semibold">
                      Business Address
                    </FormLabel>
                    <FormControl
                      type="text"
                      placeholder="Business Address"
                      {...formik.getFieldProps("company_address")}
                    ></FormControl>
                  </FormGroup>
                </Col>
                <Col lg="6">
                  <FormGroup className="mb-4">
                    <FormLabel className="fw-semibold">
                      Business Phone Number
                    </FormLabel>
                    <FormControl
                      type="text"
                      placeholder="+234-xxx-xxx-xxxx"
                      {...formik.getFieldProps("company_phone_number")}
                    ></FormControl>
                  </FormGroup>
                </Col>
              </Row>
              <div className="text-end my-80 mb-5">
                <Link to="/dashboard" className="btn btn-outline-primary me-3">
                  Cancel
                </Link>
                <button
                  type="submit"
                  className="btn btn-primary text-white me-3"
                >
                  Update Details
                </button>
              </div>
            </form>
          </div>
        )}
      </Col>
    </>
  );
}
