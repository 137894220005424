import { Container, Row } from "react-bootstrap";
import Header from "../../components/Header";
import Sidenav from "../../components/Sidenav";
import History from "./components/History";

export default function EmployeeLoans({ user }) {
  return (
    <>
      <Header title={"EMPLOYEES"} />
      <div className="bg-primary">
        <Container fluid>
          <Row>
            <Sidenav />
            <History user={user} />
          </Row>
        </Container>
      </div>
    </>
  );
}
