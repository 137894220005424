import { useState } from "react";
import { Alert, Button, Col, Modal, Row } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import Loader from "../../components/Loader";
import ErrorComponent from "../../components/ErrorComponent";
import { useFetch } from "../../hooks/useFetch";

export default function Details() {
  const { id, type } = useParams();
  const [loader, setLoader] = useState(null);
  const { data, loading, error, postData, setError, setUrl } = useFetch(
    `${process.env.REACT_APP_BASE_API}/loans/api/${type}/loan-detail/${id}/`
  );

  const denyLoan = (e) => {
    e.preventDefault();
    setLoader({ deny: true });
    setUrl(
      `${process.env.REACT_APP_BASE_API}/loans/api/${type}/loan-update/${id}/`
    );
    postData("PUT", { ...data, is_approved: 3 }, () =>
      setLoader({ modal: "deny" })
    );
  };

  const approveLoan = (e) => {
    e.preventDefault();
    setLoader({ approve: true });
    setUrl(
      `${process.env.REACT_APP_BASE_API}/loans/api/${type}/loan-update/${id}/`
    );
    postData("PUT", { ...data, is_approved: 2 }, () =>
      setLoader({ modal: "approve" })
    );
  };

  return (
    <>
      <Col lg="10" className="main-section font-source">
        {loading && <Loader />}
        {error?.status === 404 ? (
          <ErrorComponent
            message={
              "The requested loan was not found. Please check and try again."
            }
          />
        ) : (
          <div className="px-lg-4 px-3">
            {error?.status && error?.status !== 404 && (
              <Row className="mt-5">
                <Col xs="12">
                  <Alert
                    variant={"danger"}
                    className="fade text-center"
                    onClose={() => setError(null)}
                    dismissible
                  >
                    Something went wrong. Please try again.
                  </Alert>
                </Col>
              </Row>
            )}
            <h2 className="fw-700 text-muted mt-5 mb-4 pt-2">
              {data?.status !== null
                ? "Current Loan"
                : "Loan Request"}
            </h2>
            <div className="card_container p-4 rounded-4 mb-5">
              <Row>
                <Col xs="6" className="mb-5">
                  <p className="text-muted mb-0">Amount Taken:</p>
                  <h5 className="fw-700 inter mt-0">
                    {data?.loan_amount ? (
                      <>
                        <span className="naira">N</span>
                        {parseFloat(data?.loan_amount).toLocaleString()}
                      </>
                    ) : (
                      "--"
                    )}
                  </h5>
                </Col>
                <Col xs="5" className="text-end mb-5">
                  <p className="text-muted mb-0">Payment Plan:</p>
                  <h5 className="fw-700 inter mt-0">
                    {data?.duration ? (
                      <>
                        <span className="d-none d-lg-inline">Periodic - </span>
                        {data?.duration}
                        {data?.duration > 1 ? " months" : " month"}
                      </>
                    ) : (
                      "--"
                    )}
                  </h5>
                </Col>
                {data?.reason_for_loan && (
                  <Col xs="12" className="mb-5">
                    <p className="text-muted mb-0">Loan Purpose:</p>
                    <h5 className="fw-700 inter mt-0">
                      {data?.reason_for_loan}
                    </h5>
                  </Col>
                )}
                {data?.tool && (
                  <Col xs="6" className="mb-5">
                    <p className="text-muted mb-0">Work Tool:</p>
                    <h5 className="fw-700 inter mt-0">{data?.tool}</h5>
                  </Col>
                )}
                {data?.cost_of_tool && (
                  <Col xs="6" lg={5} className="mb-5 text-end">
                    <p className="text-muted mb-0">Cost of Work Tool:</p>
                    <h5 className="fw-700 inter mt-0">
                      <span className="naira">N</span>
                      {parseFloat(
                        data?.cost_of_tool.replace(/,/g, "")
                      ).toLocaleString()}
                    </h5>
                  </Col>
                )}
                <hr />
                <Col xs="12" className="mb-5">
                  <p className="text-muted mb-0">Date Taken:</p>
                  <h5 className="fw-700 inter mt-0">
                    {data?.start_date?.split("T")[0]}
                  </h5>
                </Col>
                <Col xs="6" className="mb-5">
                  <p className="text-muted mb-0">Next Payment Date:</p>
                  <h5 className="fw-700 inter mt-0">
                    {data?.status !== 3 &&
                    (data?.stage === 0 || data?.stage === 3) ? (
                      <>
                        {data?.next_payment_date
                          ? data?.next_payment_date.split("T")[0]
                          : "--"}
                      </>
                    ) : (
                      "--"
                    )}
                  </h5>
                </Col>
                <Col xs="6" lg={5} className="text-end mb-5">
                  <p className="text-muted mb-0">Amount to be paid:</p>
                  <h5 className="fw-700 inter mt-0">
                    {data?.status !== 3 && data?.is_accepted !== 3 ? (
                      <>
                        {data?.amount_to_be_paid ? (
                          <>
                            <span className="naira">N</span>
                            {parseFloat(
                              Math.ceil(data?.amount_to_be_paid)
                            ).toLocaleString()}
                          </>
                        ) : (
                          "--"
                        )}
                      </>
                    ) : (
                      "--"
                    )}
                  </h5>
                </Col>
                <hr />
                <Col xs="12" className="mb-5">
                  <p className="text-muted mb-0">Interest Rate:</p>
                  <h5 className="fw-700 inter mt-0">
                    {data?.interest_rate ? `${data?.interest_rate}%` : "--"}
                  </h5>
                </Col>
                <Col xs="12" className="mb-5">
                  <p className="text-muted mb-0">Total amount to be repaid:</p>
                  <h5 className="fw-700 inter mt-0">
                    {data?.amount_to_be_repaid ? (
                      <>
                        <span className="naira">N</span>
                        {parseFloat(data?.amount_to_be_repaid).toLocaleString()}
                      </>
                    ) : (
                      "--"
                    )}
                  </h5>
                </Col>
                <Col xs="6" className="mb-5">
                  <p className="text-muted mb-0">Amount Repaid:</p>
                  <h5 className="fw-700 inter mt-0">
                    {data?.amount_repaid ? (
                      <>
                        <span className="naira">N</span>
                        {parseFloat(data?.amount_repaid).toLocaleString()}
                      </>
                    ) : (
                      "--"
                    )}
                  </h5>
                </Col>
                <Col xs="5" className="text-end mb-5">
                  <p className="text-muted mb-0">Total amount left:</p>
                  <h5 className="fw-700 inter mt-0">
                    {data?.amount_to_be_repaid && data?.is_accepted !== 3 ? (
                      <>
                        <span className="naira">N</span>
                        {parseFloat(
                          data?.amount_to_be_repaid - data?.amount_repaid
                        ).toLocaleString()}
                      </>
                    ) : (
                      "--"
                    )}
                  </h5>
                </Col>
                {data?.is_approved === 1 && (
                  <Col xs="11" className="justify-content-end my-5 d-flex">
                    <form className="me-4" onSubmit={denyLoan}>
                      <button
                        type="submit"
                        disabled={!error && loader}
                        className="btn text-primary me-3 fw-700 inter"
                      >
                        {!error && loader?.deny ? (
                          <>
                            Please wait...&nbsp;
                            <div
                              className="spinner-border text-light"
                              role="status"
                            ></div>
                          </>
                        ) : (
                          "Deny Loan"
                        )}
                      </button>
                    </form>
                    <form onSubmit={approveLoan}>
                      <Button type="submit" disabled={!error && loader}>
                        {!error && loader?.approve ? (
                          <>
                            Processing...&nbsp;
                            <div
                              className="spinner-border text-light"
                              role="status"
                            ></div>
                          </>
                        ) : (
                          "Approve Loan"
                        )}
                      </Button>
                    </form>
                  </Col>
                )}
              </Row>
            </div>
          </div>
        )}
      </Col>
      {loader?.modal && (
        <Modal centered show={true} className="modalWrapper">
          <Modal.Body className="bg-white text-center p-5">
            <p className="mb-3 inter fw-600">
              The loan request has been
              {loader?.modal === "deny" ? " denied." : " approved."}
            </p>
            <Link to="/employees" className="btn btn-primary text-white">
              Back to Employees
            </Link>
          </Modal.Body>
        </Modal>
      )}
    </>
  );
}
